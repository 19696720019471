<template>
  <module-list-layout :createAction="false" :header="false" :module="module" v-if="amReady" style="width: 1200px !important;" class="mt-3">

    <h4>Quick Notes</h4>
    <exit-button></exit-button>

    <el-dialog :visible.sync="dialogVisible" title="" top="3vh" width="1200px" custom-class="">
      <accounting-notes :on-edit-callback="afterEdit" v-if="dialogVisible" title="External Notes" :client_id="selectedClientId" :noteType="2"></accounting-notes>
    </el-dialog>

    <div class="row">
      <div class="col-12 col-md-6 m-0">
        <dg-text-field
            style="max-width: 400px"
            class="mt-1"
            :field_span="24"
            :label_span="0"
            field_mark=""
            :defaultValue="userSearch"
            :translateLabel="false"
            label=""
            placeholder="search here"
            @amUpdated="userSearchUpdated"
            :liveUpdate="true"></dg-text-field>
      </div>
      <div class="col-12 col-md-6 m-0 float-right text-right">
         <span style="display: inline-block;margin-right: 20px;">
               <small> records: {{ recordsCounter }} | {{ pageChunkSize }} record per page</small>
        </span>
        <pagination
            style="margin-right: 20px;"
            class="mt-1 d-inline-block"
            :currentPage="currentPage"
            paginateClass=""
            :page-count="Math.ceil(recordsCounter / pageChunkSize)"
            :page-range="4"
            :margin-pages="2"
            :navigateHandler="navigateToPage"></pagination>
      </div>
    </div>

    <el-table :data="tableData" :cell-class-name="setCellClass"  style="width: 100%;margin-top: -10px;" @selection-change="handleTableSelectionChange"
              :row-class-name="tableRowClassName">

      <el-table-column
          label="Business Name"
          prop="business_name"
          width="250"
          sortable>
      </el-table-column>
      <el-table-column
          align="right"
          label="Note"
          prop="">
        <template slot-scope="scope">
          <el-button @click="openInternalNotes(scope.row)" type="info" size="small" class="p-1"><i class="el-icon-edit-outline"></i> Notes</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="deleteSelectedDialog" center title="Warning" width="420px" :append-to-body="true">
      <div align="center" class="mt-2 mb-2">Are you sure you want to remove all records ?</div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="deleteSelectedDialog = false" size="small">Cancel</el-button>
        <el-button @click="removeSelectedApi" size="small" type="danger">Yes</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="deleteDialogVisible" center title="Warning" width="420px">
      <div align="center" class="mt-2 mb-2">You want to remove this record ?</div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="deleteDialogVisible = false" size="small">Cancel</el-button>
        <el-button @click="removeActionApi" size="small" type="danger">Yes</el-button>
      </span>
    </el-dialog>
  </module-list-layout>
</template>

<script>
import module from './../../index'
export default {
  mixins: [$mixins['listView']],
  components: {
    'accounting-notes': () => import('@/modules/Accounting/Note/form/View'),
    'exit-button': () => import('@/components/exit-button/View')
  },
  methods: {
    setCellClass({row, rowIndex}) {
      return 'custom-data-table-cell-business';
    },
    tableRowClassName ({row, rowIndex}) {
      let o = ''
      if (row.external_note  != '') o += 'app-gold-bg'

      return o
    },
    openInternalNotes (client) {
      this.selectedClientId = client.id
      this.dialogVisible = true
    },
    ...$mapActions(module.info.fullName + '-api', {getEntityList: 'list', updateEntity: 'update', multipleDelete: "multipleDelete"}),
    getEntityListData (page, onLoaded = false) {
      this.amReady = false
      this.getEntityList({page: page, search: this.userSearch, account_status: '-all-',
        person_in_charge: '-all-',
        legal_structure: '-all-',
        business_type: '-all-',
        account_type: '-all-',
        province: '-all-'}).then(response => {
        this.tableData = response.rows
        this.recordsCounter = response.count
        this.amReady = true
        this.currentPage = page

        this.listFields = this.listConfig.fields || []
        if (this.reopenDialogAfterRefresh) {
          this.reopenDialogAfterRefresh = false
          this.dataToBind = response.rows[this.rowToEdit-1]
          this.dialogVisible = true
        }
        if (onLoaded) onLoaded(response)
      })
    },
  },
  computed: {
    ...$mapState(module.info.fullName + '-api', ['pageChunkSize']),
  },
  beforeMount() {
    /*let promises = []
    promises.push($store.dispatch('Product-Brand-api/getDropDownList').then())
    Promise.all(promises).then((values) => {
      this.loadPageFromUrl()
    })*/

    this.currentPage = parseInt(this.$route.params.listPage)
    this.getEntityListData(this.currentPage)
  },
  data() {
    return {
      selectedClientId: false,
      module: {...module},
      usersActions: [
        {
          label: ' View / Edit',
          icon: 'el-icon-edit-outline',
          action: 'editAction',
          notForProfiles: []
        },
        {
          label: 'Remove',
          icon: 'el-icon-delete',
          action: 'removeAction',
        },
      ]
    };
  }
}
</script>
